// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature master-invoice
import { ACCESSORIALS_OPTIONS } from '../constants';
import { ChargeItemTotal, ChargeItemFuelRelated } from '../components/charges-ui';
//////////////////////////////////////////////////

const tabOptions = [
  {
    value: 0,
    text: `${G.getWindowLocale('titles:additional-charges', 'Additional Charges')} (${
      G.getWindowLocale('titles:discount', 'Discount')})`,
  },
  {
    value: 1,
    text: G.getWindowLocale('titles:payment-info', 'Payment Info'),
  },
  {
    value: 2,
    text: G.getWindowLocale('titles:clos', 'Orders'),
  },
];

const masterInvoiceInitFields = {
  [GC.FIELD_MASTER_INVOICE_NUMBER]: null,
  [GC.FIELD_MASTER_INVOICE_STATUS]: null,
  [GC.FIELD_MASTER_INVOICE_CHARGES]: null,
  [GC.FIELD_MASTER_INVOICE_DATE_TO]: null,
  [GC.FIELD_MASTER_INVOICE_NET_DAYS]: null,
  [GC.FIELD_MASTER_INVOICE_DUE_DATE]: null,
  [GC.FIELD_MASTER_INVOICE_CURRENCY]: null,
  [GC.FIELD_MASTER_INVOICE_DATE_FROM]: null,
  [GC.FIELD_MASTER_INVOICE_CHECK_DATE]: null,
  [GC.FIELD_MASTER_INVOICE_CHECK_NUMBER]: null,
  [GC.FIELD_MASTER_INVOICE_CHECK_AMOUNT]: null,
  [GC.FIELD_MASTER_INVOICE_DEPOSIT_DATE]: null,
};

const chargeInitFields = {
  [GC.FIELD_CHARGE_RATE]: '',
  [GC.FIELD_CHARGE_TOTAL]: 0,
  [GC.FIELD_CHARGE_TYPE]: null,
  [GC.FIELD_CHARGE_QUANTITY]: '',
  [GC.FIELD_CHARGE_RATE_NAME]: null,
  [GC.FIELD_CHARGE_RATE_TYPE]: null,
  [GC.FIELD_CHARGE_RATE_UNIT]: null,
  [GC.FIELD_CHARGE_DISCOUNT]: false,
  [GC.FIELD_CHARGE_NON_TAXABLE]: null,
  [GC.FIELD_CHARGE_SEQUENCE_NUM]: null,
  [GC.FIELD_CHARGE_RATE_NAME_STORED]: null,
};

const fieldsWrapperStyles = { mb: 30, width: '100%' };

const inputWrapperStyles = { mr: 15, flexGrow: 1, flexBasis: 60 };

const inputWrapperStyles2 = { flexGrow: 1, flexBasis: 60 };

const rateTypeOptions = [
  { label: G.getWindowLocale('titles:flat', 'Flat'), value: GC.CHARGE_RATE_TYPE_FLAT },
];

const masterInvoiceChargeFieldset = [
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    closeMenuOnScroll: true,
    shouldCustomChange: true,
    useMenuPortalTarget: true,
    options: ACCESSORIALS_OPTIONS,
    label: ['titles:charge', 'Charge'],
    fieldName: GC.FIELD_CHARGE_RATE_NAME,
    customChangeHandler: 'handleChangeChargeAccessorial',
  },
  {
    type: 'checkbox',
    fieldName: GC.FIELD_CHARGE_DISCOUNT,
    label: ['titles:discount', 'Discount'],
    inputWrapperStyles: { ...inputWrapperStyles, flexGrow: 0.5, justifyContent: 'center'},
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    shouldCustomChange: true,
    label: ['titles:rate', 'Rate'],
    fieldName: GC.FIELD_CHARGE_RATE,
    customChangeHandler: 'handleChangeCharge',
  },
  {
    type: 'select',
    disabled: true,
    isRequired: false,
    inputWrapperStyles,
    options: rateTypeOptions,
    label: ['titles:type', 'Type'],
    fieldName: GC.FIELD_CHARGE_RATE_TYPE,
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    shouldCustomChange: true,
    label: ['titles:qty', 'Qty'],
    fieldName: GC.FIELD_CHARGE_QUANTITY,
    customChangeHandler: 'handleChangeCharge',
  },
  {
    type: 'select',
    inputWrapperStyles,
    options: 'glCodeOptions',
    shouldCustomChange: true,
    label: ['titles:gl', 'GL'],
    fieldName: GC.FIELD_GL_CODE,
    customChangeHandler: 'handleChangeCharge',
    disabled: (_: any, __: any, { glDisabled }: Object) => G.isTrue(glDisabled),
  },
  {
    type: 'customComponent',
    Component: ChargeItemFuelRelated,
    fieldName: GC.FIELD_CHARGE_NON_TAXABLE,
    inputWrapperStyles: { ...inputWrapperStyles, flexGrow: 0.5, justifyContent: 'center'},
  },
  {
    type: 'customComponent',
    Component: ChargeItemTotal,
    inputWrapperStyles: { ...inputWrapperStyles2, justifyContent: 'center'},
  },
];

const masterInvoiceFieldsetSettings = [
  {
    fieldsWrapperStyles,
    fields: [
      {
        type: 'text',
        isRequired: true,
        inputStyles: { pr: 15 },
        fieldName: GC.FIELD_MASTER_INVOICE_NUMBER,
        inputWrapperStyles: { mr: 15, flexGrow: 0, flexBasis: 110 },
        label: ['titles:master-invoice-number', 'Master Invoice  #'],
      },
      {
        type: 'datePicker',
        inputWrapperStyles,
        label: ['titles:date', 'Date'],
        fieldName: GC.FIELD_MASTER_INVOICE_DATE,

      },
      {
        type: 'select',
        options: GC.FIELD_MASTER_INVOICE_STATUS,
        inputWrapperStyles: inputWrapperStyles2,
        fieldName: GC.FIELD_MASTER_INVOICE_STATUS,
        label: ['titles:payroll-status', 'Payroll Status'],
      },
    ],
  },
  {
    fieldsWrapperStyles,
    fields: [
      {
        isRequired: true,
        type: 'datePicker',
        inputWrapperStyles,
        label: ['titles:date-from', 'Date From'],
        fieldName: GC.FIELD_MASTER_INVOICE_DATE_FROM,
      },
      {
        isRequired: true,
        type: 'datePicker',
        inputWrapperStyles,
        label: ['titles:date-to', 'Date To'],
        fieldName: GC.FIELD_MASTER_INVOICE_DATE_TO,
      },
      {
        type: 'select',
        isRequired: true,
        options: GC.CURRENCY_OPTIONS_2,
        fieldName: GC.FIELD_PAYROLL_CURRENCY,
        label: ['titles:currency', 'Currency'],
        inputWrapperStyles: inputWrapperStyles2,
      },
    ],
  },
  {
    fieldsWrapperStyles: { width: '100%' },
    fields: [
      {
        type: 'textarea',
        inputStyles: {
          p: 15,
          height: 131,
        },
        inputWrapperStyles: {
          flexGrow: 1,
          flexBasis: 300,
        },
        fieldName: GC.FIELD_COMMENTS,
        label: ['titles:comments', 'Comments'],
      },
    ],
  },
];

const paymentInfoFieldsetSettings = [
  {
    type: 'select',
    inputWrapperStyles,
    options: GC.FIELD_GL_CODE,
    fieldName: GC.FIELD_GL_CODE,
    label: ['titles:gl-code', 'GL Code'],
    customDisabledFunction: 'handleDisableGlCode',
  },
  {
    type: 'datePicker',
    inputWrapperStyles,
    fieldName: GC.FIELD_MASTER_INVOICE_DUE_DATE,
    label: ['titles:payment-due-date', 'Payment Due Date'],
  },
  {
    type: 'number',
    inputWrapperStyles,
    fieldName: GC.FIELD_MASTER_INVOICE_NET_DAYS,
    label: ['titles:net-days', 'Net Days'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_MASTER_INVOICE_CHECK_NUMBER,
    label: ['titles:check-number', 'Check Number'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:check-amount', 'Check Amount'],
    fieldName: GC.FIELD_MASTER_INVOICE_CHECK_AMOUNT,
  },
  {
    type: 'datePicker',
    inputWrapperStyles,
    label: ['titles:check-date', 'Check Date'],
    fieldName: GC.FIELD_MASTER_INVOICE_CHECK_DATE,
  },
  {
    type: 'datePicker',
    inputWrapperStyles: inputWrapperStyles2,
    label: ['titles:deposit-date', 'Deposit Date'],
    fieldName: GC.FIELD_MASTER_INVOICE_DEPOSIT_DATE,
  },
];

export {
  tabOptions,
  chargeInitFields,
  masterInvoiceInitFields,
  paymentInfoFieldsetSettings,
  masterInvoiceChargeFieldset,
  masterInvoiceFieldsetSettings,
};
