import React from 'react';
import * as R from 'ramda';
import {
  pure,
  compose,
  lifecycle,
  withHandlers,
} from 'react-recompose';
// components
import { Table } from '../../../components';
// features
import { orderColumnSettings as columnSettings } from '../../dispatch-report/settings/column-settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex, StickedBox } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature master-invoice
import * as H from '../helpers';
import InvoiceDetails from './invoice-details';
//////////////////////////////////////////////////

const OrderInvoices = (props: Object) => {
  const {
    closeModal,
    parentProps,
    editInvoice,
    addDocument,
    removeInvoice,
    documentTypes,
    openFixedPopup,
    closeFixedPopup,
    masterInvoiceGuid,
  } = props;

  return (
    <StickedBox
      left='0px'
      width='100vw'
      display='inline-block'
      bg={G.getTheme('colors.white')}
    >
      <Flex
        p={15}
        width='100%'
        flexWrap='wrap'
        border='1px solid'
        alignItems='baseline'
        borderColor={G.getTheme('tables.rows.borderColor')}
      >
        {
          parentProps.orderInvoices.map((invoice: Object, i: number) => (
            <InvoiceDetails
              {...invoice}
              notesHidden={true}
              closeModal={closeModal}
              documentTypes={documentTypes}
              openFixedPopup={openFixedPopup}
              closeFixedPopup={closeFixedPopup}
              edit={() => editInvoice(invoice)}
              key={`master-invoice-invoice-${invoice.guid}-${i}`}
              addDocument={() => addDocument(invoice, masterInvoiceGuid)}
              actionsDisabled={R.pathOr(false, ['actionsDisabled'], props)}
              remove={(invoiceGuid: string, invoiceNumber: string) => removeInvoice(
                invoiceGuid,
                invoiceNumber,
                masterInvoiceGuid,
                parentProps.guid,
              )}
            />
          ))
        }
      </Flex>
    </StickedBox>
  );
};

const report = {
  fields: [
    { sequence: 2, name: GC.FIELD_PRIMARY_REFERENCE_VALUE },
    { sequence: 3, name: GC.GRC.INVOICES_TOTAL },
    { sequence: 4, name: GC.GRC.RATE_TOTAL },
    { sequence: 5, name: GC.FIELD_STATUS },
    { sequence: 6, name: GC.GRC.FIRST_EVENT_COMPLETE_DATE },
    { sequence: 7, name: GC.GRC.LAST_EVENT_COMPLETE_DATE },
    { sequence: 8, name: GC.GRC.TELS_FLEET_ASSIGNMENT_TRUCK_UNIT_ID, collection: true },
    { sequence: 9, name: GC.GRC.TELS_FLEET_ASSIGNMENT_TRAILERS_UNIT_ID, collection: true },
  ],
};

const getTableSettings = ({
  closeModal,
  addDocument,
  editInvoice,
  removeInvoice,
  documentTypes,
  openFixedPopup,
  closeFixedPopup,
  handleGetOrders,
  masterInvoiceGuid,
  handleUpdateInvoiceSuccess,
  handleRemoveInvoiceSuccess,
}: Object) => ({
  minHeight: 320,
  cellFontSize: 13,
  titleFontSize: 12,
  moreBtnCellWidth: 0,
  expandableItems: true,
  checkBoxCellWidth: 30,
  allowSelectItems: false,
  tableWrapperProps: {
    maxHeight: 'unset',
    height: 'calc(100vh - 500px)',
  },
  expandedDetailsComponent: (props: Object) => (
    <OrderInvoices
      {...props}
      closeModal={closeModal}
      documentTypes={documentTypes}
      openFixedPopup={openFixedPopup}
      closeFixedPopup={closeFixedPopup}
      masterInvoiceGuid={masterInvoiceGuid}
      editInvoice={(invoice: Object) => editInvoice(invoice, handleUpdateInvoiceSuccess)}
      addDocument={(invoice: Object, masterInvoiceGuid: string) => (
        addDocument(invoice, masterInvoiceGuid, handleGetOrders)
      )}
      removeInvoice={(
        invoiceGuid: string,
        invoiceNumber: string,
        masterInvoiceGuid: string,
        cloGuid: string,
      ) => removeInvoice(
        invoiceGuid,
        invoiceNumber,
        masterInvoiceGuid,
        ({ invoiceGuid }: Object) => handleRemoveInvoiceSuccess({ cloGuid, invoiceGuid }),
      )}
    />
  ),
});

const enhance = compose(
  withHandlers({
    handleGetOrders: (props: Object) => async () => {
      const { orders, setOrders, masterInvoiceGuid, documentTypeGuids } = props;

      const branchGuid = G.getCurrentBranchGuid();

      const reqData = {
        offset: 0,
        limit: 1000,
        fields: report.fields,
        [GC.FIELD_CURRENT_BRANCH]: branchGuid,
        searchCriteria: [{
          dataType: 'string',
          operation: 'equal',
          stringValue: masterInvoiceGuid,
          propertyName: GC.GRC.INVOICES_MASTER_INVOICE_GUID,
        }],
      };

      try {
        const res = await sendRequest('post', endpointsMap.cloList, { data: reqData });

        const { data, status } = res;

        if (G.isResponseSuccess(status)) {
          const params = {
            masterInvoiceGuid,
            [GC.FIELD_DOCUMENT_TYPE_GUIDS]: R.join(',', R.or(documentTypeGuids, [])),
          };

          const invoicesRes = await sendRequest('get', endpointsMap.customerMasterInvoiceInvoices, { params });

          const isInvoicesSuccess = G.isResponseSuccess(invoicesRes.status);

          const newOrders = G.mapIndexed(
            (order: Object, i: number) => ({
              ...order,
              expanded: R.pathOr(false, [i, 'expanded'], orders),
              orderInvoices: isInvoicesSuccess ?
              R.filter(
                R.propEq(G.getGuidFromObject(order), GC.FIELD_CLO_GUID),
                R.or(invoicesRes.data, []),
              ) :
                [],
            }),
            data.results,
          );

          setOrders(newOrders);
        }
      } catch (error) {
        G.handleException('error', 'handleGetOrders exception');
      }
    },
    handleToggleOrder: (props: Object) => ({ guid }: Object) => {
      const { orders, setOrders } = props;

      setOrders(R.map(
        (order: Object) => {
          if (R.propEq(guid, GC.FIELD_GUID, order)) {
            const { expanded } = order;

            return R.assoc('expanded', R.not(expanded), order);
          }

          return order;
        },
        orders,
      ));
    },
    handleUpdateInvoiceSuccess: (props: Object) => (data: Object) => {
      const { orders, values, setOrders, setFieldValue } = props;

      const { guid, cloGuid } = data;

      const { version } = values;

      const mappedInvoice = G.mapDropdownsObjectInEntity(
        GC.FIELD_DISPLAYED_VALUE,
        [GC.FIELD_INVOICE_STATUS, GC.FIELD_MODE],
        data,
      );

      const newOrders = R.map(
        (order: Object) => {
          if (R.propEq(cloGuid, GC.FIELD_GUID, order)) {
            const orderInvoices = R.map(
              (invoice: Object) => {
                if (R.propEq(guid, GC.FIELD_GUID, invoice)) {
                  return R.mergeRight(
                    mappedInvoice,
                    {
                      documents: invoice.documents,
                      cloPrimaryReferenceValue: invoice.cloPrimaryReferenceValue,
                      ...H.getInvoiceChargesFromResponse(mappedInvoice.charges),
                    },
                  );
                }

                return invoice;
              },
              order.orderInvoices,
            );

            return { ...order, orderInvoices };
          }

          return order;
        },
        orders,
      );

      setOrders(newOrders);
      setFieldValue(GC.FIELD_VERSION, R.inc(version));
    },
    handleRemoveInvoiceSuccess: (props: Object) => ({ cloGuid, invoiceGuid }: Object) => {
      const { orders, values, setOrders, setFieldValue } = props;

      const { version } = values;

      const newOrders = R.map(
        (order: Object) => {
          if (R.propEq(cloGuid, GC.FIELD_GUID, order)) {
            return R.assoc(
              'orderInvoices',
              R.filter(
                ({ guid }: Object) => G.notEquals(guid, invoiceGuid),
                order.orderInvoices,
              ),
              order,
            );
          }

          return order;
        },
        orders,
      );

      setOrders(newOrders);
      setFieldValue(GC.FIELD_VERSION, R.inc(version));
    },
  }),
  lifecycle({
    componentWillMount() {
      const { orders, handleGetOrders } = this.props;

      if (R.isNil(orders)) handleGetOrders();
    },
  }),
  pure,
);

const Orders = (props: Object) => {
  const { orders, handleToggleOrder } = props;

  const freezedField = {
    sequence: 1,
    freezed: true,
    name: 'orderDetails',
  };

  const data = {
    columnSettings,
    itemList: orders,
    hasSelectable: true,
    loading: R.isNil(orders),
    toggle: handleToggleOrder,
    tableSettings: getTableSettings(props),
    report: G.prependFieldToTableReportFields(report, freezedField),
  };

  return <Table {...data} />;
};

export default enhance(Orders);
