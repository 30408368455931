import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const referenceDefaultFields = {
  [GC.FIELD_VALUE]: null,
  [GC.FIELD_REFERENCE_TYPE_GUID]: null,
};

export const referenceFormInitData = {
  isValid: false,
  [GC.FIELD_LOAD_REFERENCES]: [],
  [GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS]: null,
};

export const getNewReferenceFields = () => R.assoc('uniq', G.generateGuid(), referenceDefaultFields);

export const specialInstructionsRefFields = [
  {
    type: 'textarea',
    inputStyles: {
      p: 15,
      height: 60,
    },
    inputWrapperStyles: {
      flexGrow: 1,
      flexBasis: 500,
    },
    fieldName: GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS,
  },
];

export const noteFields = [
  {
    type: 'textarea',
    inputStyles: {
      p: 15,
      height: 60,
    },
    inputWrapperStyles: {
      flexGrow: 1,
      flexBasis: 500,
    },
    fieldName: GC.FIELD_PINNED_NOTE,
  },
];

export const referenceFields1 = [
  {
    isRequired: true,
    type: 'reactSelect',
    inputWrapperStyles: {
      mr: 20,
      width: 200,
    },
    options: 'referenceTypes',
    fieldName: GC.FIELD_REFERENCE_TYPE_GUID,
    label: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_VALUE,
    inputWrapperStyles: { width: 200 },
    label: G.getWindowLocale('titles:value', 'Value'),
  },
];

export const referenceFields2 = [
  {
    isRequired: true,
    type: 'reactSelect',
    inputWrapperStyles: {
      mr: 20,
      width: 200,
    },
    options: 'referenceTypes',
    fieldName: GC.FIELD_REFERENCE_TYPE_GUID,
    label: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    isRequired: true,
    type: 'reactSelect',
    options: 'allowedValues',
    fieldName: GC.FIELD_VALUE,
    inputWrapperStyles: { width: 200 },
    label: G.getWindowLocale('titles:value', 'Value'),
  },
];
